@font-face {
  font-family: "Wriggle";   /*Can be any text*/
  src: local("Wriggle-Regular"),
    url("./fonts/Wriggle-Regular.otf") format("otf");
}

@font-face {
  font-family: "Dirtyline";   /*Can be any text*/
  src: local("Dirtyline"),
    url("./fonts/Dirtyline.woff") format("woff");
}

@font-face {
  font-family: "RemboyRegular";   /*Can be any text*/
  src: local("RemboyRegular"),
    url("./fonts/RemboyRegular.ttf") format("truetype");
}

/* background: rgb(180,176,166);  background: linear-gradient(90deg, rgba(180, 176, 166, 0.948) 0%, rgba(232,228,217,1) 100%); 
background-image: url('./assets/bg.png');
background-image: url('./assets/bgTexture_v3.png');
  box-shadow: inset 0 0 0 1000px rgba(180,176,166, 0.2);
*/





/**Nav**/
nav{
  padding-left: 30%;
  padding-right: 30%;
  align-items: center;
  align-self: center;

}

#navDiv{
  
  font-family: contralto-big, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  
}
#navDiv p a{
  text-decoration: none;
  color:black
}
#navDiv p a:hover{
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.421);
  transition: 0.3s;
}



/*  */

#backgroundImage{
  top:0;
  left:0;
  z-index: -2;
  position: absolute;
  width: 100%;
  height: auto;
  
  
  
}
:root{
  background: rgb(180,176,166);  background: linear-gradient(90deg, rgba(180, 176, 166, 0.948) 0%, rgba(232,228,217,1) 100%); 
  background-repeat: no-repeat;
  background-size: cover;  
  scroll-behavior: smooth;
  
  
}


body {
  margin: 1%;
  margin-left: 2%;
  margin-right: 2%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

#canvas-container{
  
  z-index: -1;
  position:absolute;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
}

.fullPage{
  height: 100vh;
  
}

.subtitle{
  margin-bottom: 0;
  margin-top: 0%;
  font-size: 4vw;
  font-family: "RemboyRegular";
  font-weight: 500;
}
#contactContainer{
  height: 95vh;    
  overflow: hidden;
}
/*95*/
#dataWorkPage{
  height: 205vh;
}
