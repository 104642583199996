/**General**/




  /**Mainpage**/
  
  #introTekst{
    margin-top: 2%;
    margin-left: 10%;
    width: 40%;
    font-size: 2vw;
    font-family: contralto-big, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  #topHeadTekst{
    margin-left: 40%;
    margin-top:4%;
    margin-bottom: -2%;
    width: 40%;
    font-size: 2vw;
    font-family: contralto-big, sans-serif;
  }
  .introSpans{
    font-weight: 800;
    font-family: contralto-big, sans-serif;
  }
  
  #headTitle{
    margin-bottom: 0;
    margin-top: 0%;
    font-size: 10vw;
    font-family: "RemboyRegular";
    font-weight: 500;
  }
  #scrollDownDiv{
    width: 100%;
    align-items: center;
    text-align: center;
  }
  #scrollDownTekst{
    margin-top:17%;
    font-size: 1.5vw;
    font-weight: 500;
    font-family: contralto-big, sans-serif;
  }

  .activeItem{
    font-weight: 800;
    text-decoration:underline;
  }
  
  
  
  