#alertMobile{
    display: none;
}
.App{
    display: block;
}


@media only screen and (max-width: 480px) {
   
    *{
        background-color: white;
        overflow: hidden;
    }
    #alertMobile{
        width: 100%;
        position: fixed;
        z-index: 5;
        margin: 0; height: 100%; overflow: hidden;
        display: block;
        text-align: center;
        padding-top: 50%;
       
    }
    #alertMobile p{
        padding-left: 20%;
        padding-right: 20%;
        font-size: x-large;
    }
    
    
}


