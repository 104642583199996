#containerAboutPage{
    padding-left: 8%;
}
#aboutMeText{
    
    height: auto;
    font-family: contralto-small, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    justify-content: space-between;
}
#aboutMeText p{
    width: 30%;
}
#aboutIMG{
    padding-right: 0%;
    width: 30%;
    height: auto;
    z-index: -3;

}
