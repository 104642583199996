/******Navbar*****/
#navigatieWork{
    display: flex;
    justify-content: center;
  }
  
  #navigatieWork p{
    padding:1%;
    ;
  
  }
  
  #navigatieWork, #workTitle{
    text-align: center;
  }
  
  .navItem:hover{
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.421);
    transition: 0.3s;
  }

/******General*******/

#workContainer{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    
}
#workContainer div{
  cursor:pointer;
}
.worksPost{
    margin:1%;
    background-color: rgb(221, 160, 85);
    width: 25%;
    padding-bottom: 10%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;  
    border-radius:2%;
    position: relative;
    
}

p{
    font-family: contralto-big, sans-serif;  
}

.worksTitles{
    font-weight: 800;
    font-size: 2vw;
    margin-bottom: 0;
    color:white;
    text-shadow: 0px 0px 20px black;
    pointer-events: none;
    
}
.worksDescriptions{
    padding-left: 5%;
    padding-right: 5%;
    font-style: italic;
    font-size: 0.8vw;
    color:white;
    text-shadow: 0px 0px 10px black;
    pointer-events: none;

}
.worksImages{
    width: 80%;
    height: auto;
}
.worksLinks{
    text-decoration: none;
    color: rgb(255, 255, 255);
    text-shadow: 0px 0px 5px black;
    position: absolute;
    bottom: 1%;
    left: 1%;
  padding: 1.5%;
  border-radius: 2%;
  align-items: center;
  text-align: center;
  cursor:pointer;
    
}
.workLinkDiv{
  cursor:pointer;
  pointer-events:none;
  height: 100%;
  align-items:baseline;
  text-align: center;
  vertical-align: bottom;
  
}